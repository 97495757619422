import axios from '@/services/axios-auth-wrapper';

export default {

    // getSurveyXml: (surveyId) => {
    //     return axios.get(url + surveyId);
    // },
    calculateSurveyMetrics: (surveyId) => {
        return axios.post(url + surveyId + '/calculate');
    }
    // pollSurvey: (surveyId) => {
    //     return axios.get(url + surveyId + '/poll');
    // },
};

const url = process.env.VUE_APP_INDIGO_API_URL + '/api/surveymetrics/';
