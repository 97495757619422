import axios from '@/services/axios-auth-wrapper';

export default {
    getSurveyFileForSurvey: (surveyId) => {
        return axios.get(url + 'survey/' + surveyId);
    },
    addSurveyFile: (data) => {
        return axios.post(url + 'create', data, {
            headers: {
                'Content-Type': 'multipart/form-data;'
            }
        });
    },
    deleteSurveyFile: (id) => {
        return axios.delete(url + id);
    },
    downloadSurveyFile: (id) => {
        return axios.get(url + id + '/download', { responseType: 'blob' });
    },
    processSurveyFile: (surveyFileId) => {
        return axios.get(url + surveyFileId + '/process');
    }
};

const url = process.env.VUE_APP_INDIGO_API_URL + '/api/surveyfile/';
