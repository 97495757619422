<template>
    <div>
        <div style="margin:auto;" v-if="datamap" class="datamap-lists">
            <b-collapse aria-id="contentIdForA11y2" class="panel" animation="slide" v-model="isAudienceOpen">
                <template #trigger>
                    <div class="panel-heading" role="button" aria-controls="contentIdForA11y2">
                        <div>
                            <strong>Audiences</strong>
                            &nbsp;
                            <span> ({{includedAudienceCount}} of {{audienceCount}} included)</span>
                        </div>
                    </div>
                </template>
              <b-field>
                  <b-input
                      rounded
                      v-model="filterAudiencesTerm"
                      placeholder="Filter Audiences..."
                      icon="search"
                  >
                  </b-input>
              </b-field>
              <div class="panel-block">
                <b-table
                    class="is-full-width"
                    :data="filteredAudiences"
                    :paginated="false"
                    :default-sort-direction="'asc'"
                    :striped="true"
                    default-sort="id"
                >
                    <template>
                        <b-table-column field="name" label="Name" sortable v-slot="props">
                            <span v-if="!props.row.isEdit">
                                {{props.row.name}}
                            </span>
                            <b-input v-else
                                v-model="props.row.name"
                                class="has-no-margin" custom-class="has-text-centered">
                            </b-input>
                        </b-table-column>

                        <b-table-column field="type" label="Group" sortable v-slot="props">
                            {{ props.row.type }}
                        </b-table-column>
                        <b-table-column field="isIncluded" label="Include" sortable v-slot="props">
                            <input type=checkbox
                                   v-model="props.row.isIncluded"
                                   :disabled="isAudienceTypeAlwaysIncluded(props.row.type)"
                                   :title="isAudienceTypeAlwaysIncluded(props.row.type) ? 'Always Included in XML' : 'Include in XML?'"/>
                        </b-table-column>
                        <b-table-column width="100" v-slot="props">
                            <b-button
                                v-if="!props.row.isEdit"
                                icon-left="edit"
                                class="button is-outlined is-inline-block"
                                title="Edit name"
                                @click="startEditingAudienceName(props.row)">
                            </b-button>
                            <b-button
                                v-else
                                icon-left="check"
                                class="button is-outlined is-inline-block"
                                @click="finishEditingAudienceName(props.row)">
                            </b-button>
                        </b-table-column>
                    </template>
                    <!-- Empty Table Template -->
                    <template slot="empty">
                        <empty-table-content/>
                    </template>
                </b-table>
              </div>
            </b-collapse>

            <b-collapse aria-id="contentIdForA11y2" class="panel" animation="slide" v-model="isSyntheticAudienceOpen">
                <template #trigger>
                    <div class="panel-heading" role="button" aria-controls="contentIdForA11y2">
                        <div>
                            <strong>Synthetic Audiences</strong>
                            &nbsp;
                            <span> ({{syntheticAudienceCount}})</span>
                        </div>
                        <div class="panel-heading-right-btn">
                            <button class="button is-primary is-outlined" @click.stop="addSyntheticAudience">Add</button>
                        </div>
                    </div>
                </template>
              <b-field>
                  <b-input
                      rounded
                      v-model="filterSyntheticAudiencesTerm"
                      placeholder="Filter Synthetic Audiences..."
                      icon="search"
                  >
                  </b-input>
              </b-field>
              <div class="panel-block">
                <b-table
                    class="is-full-width left-align"
                    :data="filteredSyntheticAudiences"
                    :paginated="false"
                    :default-sort-direction="'asc'"
                    :striped="true"
                    default-sort="name"
                >
                    <template>
                        <b-table-column field="name" label="Name" sortable v-slot="props">
                            {{props.row.name}}
                        </b-table-column>
                        <b-table-column field="composition" label="Composition" sortable v-slot="props" width=200>
                            {{ props.row.composition }}
                        </b-table-column>
                        <b-table-column label="Number of Selected Audiences" sortable v-slot="props" width=250>
                            {{ (props.row.audiences ? props.row.audiences.length : 0)
                                + (props.row.syntheticAudiences ? props.row.syntheticAudiences.length : 0) }}
                        </b-table-column>
                        <b-table-column width="100" v-slot="props">
                            <div class="buttons is-grouped">
                            <button class="button is-outlined" @click="editSyntheticAudience(props.row)">
                                <b-icon
                                    icon="edit"
                                    type="is-primary"
                                >
                                </b-icon>
                            </button>
                            <button class="button is-outlined" @click="showConfirmDeleteSyntheticAudienceModal(props.row)">
                                <b-icon
                                    icon="trash"
                                    type="is-danger"
                                >
                                </b-icon>
                            </button>
                            </div>
                        </b-table-column>
                    </template>
                    <!-- Empty Table Template -->
                    <template slot="empty">
                        <empty-table-content/>
                    </template>
                </b-table>
              </div>
            </b-collapse>

            <b-collapse aria-id="contentIdForA11y2" class="panel" animation="slide" v-model="isTouchpointsOpen">
                <template #trigger>
                    <div class="panel-heading" role="button" aria-controls="contentIdForA11y2">
                        <div>
                            <strong>Touchpoints</strong>
                            &nbsp;
                            <span>({{includedTouchpointCount}} of {{touchpointCount}} included)</span>
                        </div>
                        <div v-if="includedTouchpointCount > warningTouchpointCount" class="panel-heading-warning">
                            <i class="fas fa-exclamation-circle"></i>
                            More than {{warningTouchpointCount}} Touchpoints have been included
                        </div>
                    </div>
                </template>
              <b-field>
                  <b-input
                      rounded
                      v-model="filterTouchpointsTerm"
                      placeholder="Filter Touchpoints..."
                      icon="search"
                  >
                  </b-input>
              </b-field>
              <div class="panel-block">
                <b-table
                    class="is-full-width"
                    :data="filteredTouchpoints"
                    :paginated="false"
                    :default-sort-direction="'asc'"
                    :striped="true"
                    default-sort="name"
                    row-height="50"
                >
                    <template>
                        <b-table-column field="name" label="Name" sortable v-slot="props">
                            <span v-if="!props.row.isEdit">
                                {{props.row.abbreviation === null ? props.row.name : props.row.abbreviation}}
                            </span>
                            <div v-else class="input-with-limit">
                                <b-input
                                    v-model="props.row.abbreviation"
                                    class="has-no-margin has-full-width" custom-class="has-text-centered">
                                </b-input>
                                <span :class="{isRed:ifReachedLimitOf(props.row.abbreviation)}" title="Remaining characters allowed">
                                    {{tpAbbreviationLimitCount(props.row)}}
                                </span>
                            </div>
                        </b-table-column>
                        <b-table-column width="100" v-slot="props">
                            <b-button
                                v-if="!props.row.isEdit"
                                icon-left="edit"
                                class="button is-outlined is-inline-block"
                                title="Edit name"
                                @click="startEditingTouchpointName(props.row)">
                            </b-button>
                            <b-button
                                v-else
                                icon-left="check"
                                class="button is-outlined is-inline-block"
                                @click="finishEditingTouchpointName(props.row)">
                            </b-button>
                        </b-table-column>
                    </template>
                </b-table>
              </div>
            </b-collapse>

            <b-collapse aria-id="contentIdForA11y2" class="panel" animation="slide" v-model="isBrandsOpen">
                <template #trigger>
                    <div class="panel-heading" role="button" aria-controls="contentIdForA11y2">
                        <div>
                            <strong>Brands</strong>
                            &nbsp;
                            <span>({{includedBrandCount}} of {{brandCount}} included)</span>
                        </div>
                        <div v-if="includedBrandCount > warningBrandCount" class="panel-heading-warning">
                            <i class="fas fa-exclamation-circle"></i>
                            More than {{warningBrandCount}} Brands have been included
                        </div>
                    </div>
                </template>
              <b-field>
                  <b-input
                      rounded
                      v-model="filterBrandsTerm"
                      placeholder="Filter Brands..."
                      icon="search"
                  >
                  </b-input>
              </b-field>
              <div class="panel-block">
                <b-table
                    class="is-full-width"
                    :data="filteredBrands"
                    :paginated="false"
                    :default-sort-direction="'asc'"
                    :striped="true"
                    default-sort="name"
                >
                    <template>
                        <b-table-column field="name" label="Name" sortable v-slot="props">
                            {{props.row.name}}
                        </b-table-column>
                        <!-- Commenting this code block to disable the option to select brands by the user. In furture if required we can enable it -->
                        <!-- <b-table-column field="isIncluded" label="Include" sortable v-slot="props">
                            <input type=checkbox
                                   v-model="props.row.isIncluded"
                                   title="Include in XML?"/>
                        </b-table-column> -->
                    </template>
                    <!-- Empty Table Template -->
                    <template slot="empty">
                        <empty-table-content/>
                    </template>
                </b-table>
              </div>
            </b-collapse>

        </div>

        <div style="width:50%; margin:auto;" v-if="!datamap">
            <span>
                No Processed Datamap File Available for display.
            </span>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import EmptyTableContent from '@/components/EmptyTableContent.vue';
import SyntheticAudienceForm from '@/components/SyntheticAudienceForm.vue';

export default {
    name: 'DisplayDatamap',
    components: {
        EmptyTableContent
    },
    props: {
        datamap: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            errorMessage: '',
            isMetadataOpen: true,
            isAudienceOpen: false,
            isSyntheticAudienceOpen: false,
            isTouchpointsOpen: false,
            isBrandsOpen: false,
            filterAudiencesTerm: '',
            filterSyntheticAudiencesTerm: '',
            filteredAudiences: [],
            filteredSyntheticAudiences: [],
            filterTouchpointsTerm: '',
            filteredTouchpoints: [],
            filterBrandsTerm: '',
            filteredBrands: [],
            warningTouchpointCount: 35,
            warningBrandCount: 18,
            maxTPAbbreviationLength: 50
        };
    },
    created () {
        if (this.datamap) {
            this.filteredAudiences = this.datamap.surveyDatamap.audiences.map(aud => ({
                ...aud,
                isEdit: false
            }));

            this.filteredSyntheticAudiences = this.datamap.surveyDatamap.syntheticAudiences ?? [];
            this.filteredTouchpoints = this.datamap.surveyDatamap.influenceTouchpoints.map(it => ({
                ...it,
                isEdit: false
            }));
            this.filteredBrands = this.datamap.surveyDatamap.brands;
        }
    },
    methods: {
        debounceFilterAudiences: _.debounce(function () { this.filterAudiences(); }, 250),
        filterAudiences: function () {
            const substring = this.filterAudiencesTerm.toLowerCase();
            this.filteredAudiences = this.datamap.surveyDatamap.audiences.filter(a => {
                return a.name.toLowerCase().includes(substring) ||
                    a.type.toLowerCase().includes(substring);
            });
        },
        debounceFilterSyntheticAudiences: _.debounce(function () { this.filterSyntheticAudiences(); }, 250),
        filterSyntheticAudiences: function () {
            if (this.datamap.surveyDatamap.syntheticAudiences) {
                const substring = this.filterSyntheticAudiencesTerm.toLowerCase();
                this.filteredSyntheticAudiences = this.datamap.surveyDatamap.syntheticAudiences.filter(a => {
                    return a.name.toLowerCase().includes(substring) ||
                        a.composition.toLowerCase().includes(substring);
                });
            }
        },
        debounceFilterTouchpoints: _.debounce(function () { this.filterTouchpoints(); }, 250),
        filterTouchpoints: function () {
            const substring = this.filterTouchpointsTerm.toLowerCase();
            this.filteredTouchpoints = this.datamap.surveyDatamap.influenceTouchpoints.filter(t => {
                return t.name.toLowerCase().includes(substring);
            });
        },
        debounceFilterBrands: _.debounce(function () { this.filterBrands(); }, 250),
        filterBrands: function () {
            const substring = this.filterBrandsTerm.toLowerCase();
            this.filteredBrands = this.datamap.surveyDatamap.brands.filter(b => {
                return b.name.toLowerCase().includes(substring);
            });
        },
        isAudienceTypeAlwaysIncluded: function (type) {
            return type === 'Age' || type === 'Gender' || type === 'Awareness';
        },
        addSyntheticAudience () {
            this.showSyntheticAudienceModal();
        },
        editSyntheticAudience (syntheticAudience) {
            this.showSyntheticAudienceModal(syntheticAudience);
        },
        showSyntheticAudienceModal (syntheticAudience) {
            this.$buefy.modal.open({
                parent: this,
                component: SyntheticAudienceForm,
                props: {
                    baseAudiences: this.datamap.surveyDatamap.audiences,
                    baseSyntheticAudiences: this.datamap.surveyDatamap.syntheticAudiences,
                    syntheticAudienceToEdit: syntheticAudience
                },
                hasModalCard: true,
                events: {
                    saveSyntheticAudience: (newSyntheticAudience, isUpdate) => {
                        if (!this.datamap.surveyDatamap.syntheticAudiences) {
                            this.datamap.surveyDatamap.syntheticAudiences = [];
                        }

                        // if this is an update then remove the existing synthetic audience from the list and
                        // add the new synthetic audience
                        if (isUpdate && syntheticAudience) {
                            this.removeSyntheticAudience(syntheticAudience);
                        }

                        this.datamap.surveyDatamap.syntheticAudiences.push(newSyntheticAudience);
                        this.debounceFilterSyntheticAudiences();
                    }
                }
            });
        },
        showConfirmDeleteSyntheticAudienceModal (syntheticAudienceToRemove) {
            this.$buefy.dialog.confirm({
                title: 'Delete Synthetic Audience',
                message: 'Are you sure you want to delete <b>' + syntheticAudienceToRemove.name + '</b>?',
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.removeSyntheticAudience(syntheticAudienceToRemove);
                    this.debounceFilterSyntheticAudiences();
                }
            });
        },
        removeSyntheticAudience (syntheticAudienceToRemove) {
            if (this.datamap.surveyDatamap.syntheticAudiences && syntheticAudienceToRemove) {
                const syntheticAudiences = this.datamap.surveyDatamap.syntheticAudiences;
                this.datamap.surveyDatamap.syntheticAudiences = _.filter(syntheticAudiences, (sa) => {
                    return sa.name !== syntheticAudienceToRemove.name;
                });
            }
        },
        startEditingTouchpointName (touchpoint) {
            touchpoint.isEdit = true;
        },
        finishEditingTouchpointName (touchpoint) {
            const abbreviationLength = touchpoint.abbreviation?.length || 0;
            if (abbreviationLength > this.maxTPAbbreviationLength) return;

            touchpoint.isEdit = false;

            const sourceTouchpoint = this.datamap.surveyDatamap.influenceTouchpoints.find(it => it.masterTouchpoint.id === touchpoint.masterTouchpoint.id);
            sourceTouchpoint.abbreviation = touchpoint.abbreviation;
        },
        startEditingAudienceName (audience) {
            audience.isEdit = true;
        },
        finishEditingAudienceName (audience) {
            audience.isEdit = false;

            const whereIsEqual = (a1, a2) => a1.key.item1 === a2.key.item1 && a1.key.item2 === a2.key.item2;
            const sourceAudience = this.datamap.surveyDatamap.audiences.find(a => whereIsEqual(a, audience));
            sourceAudience.name = audience.name;
        },
        tpAbbreviationLimitCount (touchpoint) {
            const remainingCharacters = this.getRemainingAllowedCharacters(touchpoint.abbreviation);
            return remainingCharacters;
        },
        ifReachedLimitOf (touchpointAbbreviation) {
            const remainingCharacters = this.getRemainingAllowedCharacters(touchpointAbbreviation);
            return remainingCharacters < 0;
        },
        getRemainingAllowedCharacters (touchpointAbbreviation) {
            const tpAbbreviationLength = touchpointAbbreviation?.length || 0;
            const remainingCharacters = this.maxTPAbbreviationLength - tpAbbreviationLength;
            return remainingCharacters;
        }
    },
    watch: {
        filterAudiencesTerm: function (newValue, oldValue) {
            this.debounceFilterAudiences();
        },
        filterSyntheticAudiencesTerm: function (newValue, oldValue) {
            this.debounceFilterSyntheticAudiences();
        },
        filterTouchpointsTerm: function (newValue, oldValue) {
            this.debounceFilterTouchpoints();
        },
        filterBrandsTerm: function (newValue, oldValue) {
            this.debounceFilterBrands();
        }
    },
    computed: {
        audienceCount () {
            return this.datamap.surveyDatamap.audiences.length;
        },
        syntheticAudienceCount () {
            return this.datamap.surveyDatamap.syntheticAudiences
                ? this.datamap.surveyDatamap.syntheticAudiences.length
                : 0;
        },
        touchpointCount () {
            return this.datamap.surveyDatamap.influenceTouchpoints.length;
        },
        brandCount () {
            return this.datamap.surveyDatamap.brands.length;
        },
        includedAudienceCount () {
            return _.filter(this.datamap.surveyDatamap.audiences, a => a.isIncluded).length;
        },
        includedTouchpointCount () {
            return _.filter(this.datamap.surveyDatamap.influenceTouchpoints, t => t.isIncluded).length;
        },
        includedBrandCount () {
            return _.filter(this.datamap.surveyDatamap.brands, b => b.isIncluded).length;
        }
    }
};
</script>

<style scoped lang="scss">
.is-full-width{
  width:100%;
  margin:auto;
}
.datamap-lists {
  .control {
    margin-left:0.5em;
    margin-right:0.5em;
    margin-top:0.5em;
  }
}

.panel-heading-warning {
    padding: 5px 10px;
    background: #F5E969;
    border-radius: 5px;
    font-size: 0.8em;
    float: right;
    margin-top: -25px;
    margin-right: -5px;
}

.panel-heading-right-btn {
    float: right;
    margin-top: -27px;
    margin-right: -10px;
}

.left-align {
    text-align: left;
}

.input-with-limit {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.has-full-width {
    width: 98%;
}

.isRed {
    color: red;
}
</style>
