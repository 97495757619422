<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                <span v-if="isEditMode">Edit</span>
                <span v-if="!isEditMode">Add</span>
                Synthetic Audience
            </p>
        </header>
        <section class="fixed-notification-in-dialog" v-if="errorMessages && errorMessages.length > 0">
            <b-notification type="is-danger" :closable="false" position="is-bottom">
                <ul :style="[errorMessages.length > 1 ? {'list-style':'inside'}: {'list-style':'none'}]">
                    <li v-for="message in errorMessages" :key="message">
                      {{ message }}
                    </li>
                  </ul>
            </b-notification>
        </section>
        <section class="modal-card-body">
            <b-field label="Name" horizontal>
                <b-input
                    type="text"
                    placeholder="Synthetic Audience Name"
                    v-model="syntheticAudience.name"
                    required>
                </b-input>
            </b-field>

            <b-field label="Composition" horizontal>
                <div class="block">
                    <b-radio v-model="syntheticAudience.composition"
                        name="composition"
                        :native-value="COMPOSITION_TYPE.AND">
                        {{ COMPOSITION_TYPE.AND }}
                    </b-radio>
                    <b-radio v-model="syntheticAudience.composition"
                        name="composition"
                        :native-value="COMPOSITION_TYPE.OR">
                        {{ COMPOSITION_TYPE.OR }}
                    </b-radio>
                </div>
            </b-field>
            <b-field label="Audiences" horizontal>
                <span> {{ selectedAudienceCount }} Selected </span>
            </b-field>

            <b-field>
                <b-input
                    rounded
                    v-model="filterAudiencesTerm"
                    placeholder="Filter Audiences..."
                    icon="search"
                >
                </b-input>
            </b-field>

            <div class="panel-block">
                <b-table
                    class="is-full-width"
                    :data="filteredAudiences"
                    :paginated="false"
                    :default-sort-direction="'asc'"
                    :striped="true"
                    default-sort="id"
                >
                    <template>
                        <b-table-column field="name" label="Name" sortable v-slot="props">
                            {{ props.row.name }}
                        </b-table-column>
                        <b-table-column field="type" label="Group" sortable v-slot="props">
                            {{ props.row.type }}
                        </b-table-column>
                        <b-table-column field="isSelected" sortable v-slot="props">
                            <input type=checkbox v-model="props.row.isSelected"/>
                        </b-table-column>
                    </template>
                    <!-- Empty Table Template -->
                    <template slot="empty">
                        <empty-table-content/>
                    </template>
                </b-table>
              </div>
        </section>
        <footer class="modal-card-foot" style="width:100%">
            <span class="is-pulled-left is-italic"> {{ requiredText }} </span>
            <button type="button" class="button" @click="onCancelAction">Cancel</button>
            <button type="button" class="button is-primary" @click="saveSyntheticAudience" :disabled="!isValidModel">{{isEditMode ? 'Update' : 'Add'}}</button>
        </footer>
    </div>
</template>

<script>
import _ from 'lodash';
import EmptyTableContent from '@/components/EmptyTableContent.vue';
export default {
    name: 'SyntheticAudienceForm',
    components: {
        EmptyTableContent
    },
    props: {
        baseAudiences: {
            type: Array,
            required: true
        },
        baseSyntheticAudiences: {
            type: Array,
            required: true
        },
        syntheticAudienceToEdit: {
            type: Object,
            required: false
        }
    },
    data () {
        return {
            isEditMode: false,
            errorMessages: [],
            filterAudiencesTerm: '',
            filteredAudiences: [],
            audiences: [],
            syntheticAudiences: [],
            existingAudienceNameList: [],
            syntheticAudience: {
                name: undefined,
                composition: undefined,
                type: 'Other', // hardcode this AudienceGroup value
                audiences: []
            },
            COMPOSITION_TYPE: {
                AND: 'AND',
                OR: 'OR'
            }
        };
    },
    created () {
        // clone the baseAudiences that are passed in, as we will be adding a property to specify whether it is selected or not.
        this.audiences = _.cloneDeep(this.baseAudiences);
        this.syntheticAudiences = this.baseSyntheticAudiences ? _.cloneDeep(this.baseSyntheticAudiences) : []; // if null create empty list

        // build existing audiences name list
        var syntheticAudienceNameList = _.map(this.syntheticAudiences, 'name');
        this.existingAudienceNameList = _.map(this.audiences, 'name').concat(syntheticAudienceNameList);

        if (this.syntheticAudienceToEdit) {
            this.isEditMode = true;
            this.syntheticAudience = Object.assign(this.syntheticAudience, this.syntheticAudienceToEdit);

            // remove this object from the list of synthetic audiences that are available for selection
            this.syntheticAudiences = _.filter(this.syntheticAudiences, (sa) => {
                return sa.name !== this.syntheticAudienceToEdit.name;
            });

            // select the audiences that are part of synthetic audience.
            _.forEach(this.syntheticAudience.audiences, (sa) => {
                var audience = _.find(this.audiences, (a) => a.name === sa.name);
                audience.isSelected = true;
            });

            // select any synthetic audiencs that are part of this synthetic audience
            _.forEach(this.syntheticAudience.syntheticAudiences, (sa) => {
                var syntheticAudience = _.find(this.syntheticAudiences, (a) => a.name === sa.name);
                syntheticAudience.isSelected = true;
            });
        }
    },
    methods: {
        debounceFilterAudiences: _.debounce(function () { this.filterAudiences(); }, 250),
        filterAudiences: function () {
            const substring = this.filterAudiencesTerm.toLowerCase();
            var allAudiences = [].concat(this.audiences).concat(this.syntheticAudiences);
            this.filteredAudiences = allAudiences.filter(a => {
                return a.name.toLowerCase().includes(substring) ||
                    a.type.toLowerCase().includes(substring);
            });
        },
        saveSyntheticAudience () {
            this.syntheticAudience.audiences = _.filter(this.audiences, (a) => a.isSelected);
            this.syntheticAudience.syntheticAudiences = _.filter(this.syntheticAudiences, (a) => a.isSelected);
            this.$emit('saveSyntheticAudience', this.syntheticAudience, this.isEditMode);
            this.$parent.close();
        },
        onCancelAction () {
            this.$parent.close();
        },
        validateSyntheticAudience () {
            const ageGroup = 'Age';
            const genderGroup = 'Gender';
            const errorMsgs = [];

            // check for duplicate audience name.
            if (this.existingAudienceNameList && this.syntheticAudience.name) {
                const isNameExist = _.some(this.existingAudienceNameList, (an) => {
                    const newName = this.syntheticAudience.name.toLowerCase();
                    // if in edit mode, check and ignore name validation if the original name and the new name is the same.
                    if (this.isEditMode) {
                        const originalName = this.syntheticAudienceToEdit.name.toLowerCase();
                        return (an.toLowerCase() === newName && newName !== originalName);
                    }
                    return an.toLowerCase() === newName;
                });
                if (isNameExist) {
                    errorMsgs.push('Audience name already exists.');
                }
            }

            // other validation
            // check if only one Age or Gender audience is selected when Composition type of "AND" is selected.
            if (this.syntheticAudience.composition === this.COMPOSITION_TYPE.AND) {
                const selectedAgeAudienceCount = _.filter(this.audiences,
                    (a) => a.type === ageGroup && a.isSelected).length;
                const selectedGenderAudienceCount = _.filter(this.audiences,
                    (a) => a.type === genderGroup && a.isSelected).length;

                if (selectedAgeAudienceCount > 1) {
                    errorMsgs.push('More than one Age audience has been selected. ' +
                        'Composition type of "AND" can only have one Age audience selected.');
                }
                if (selectedGenderAudienceCount > 1) {
                    errorMsgs.push('More than one Gender audience has been selected. ' +
                        'Composition type of "AND" can only have one Gender audience selected.');
                }
            }

            this.errorMessages = errorMsgs;
            return errorMsgs;
        }
    },
    computed: {
        selectedAudienceCount () {
            const allAudiences = [].concat(this.audiences).concat(this.syntheticAudiences);
            return _.filter(allAudiences, (a) => a.isSelected).length;
        },
        isValidModel () {
            const isRequireFieldsComplete = this.syntheticAudience.name && this.syntheticAudience.composition &&
                this.selectedAudienceCount > 0;

            return this.validateSyntheticAudience().length === 0 && isRequireFieldsComplete;
        },
        requiredText () {
            if (this.syntheticAudience.name && this.syntheticAudience.composition &&
                this.selectedAudienceCount > 0) {
                return '';
            } else if (this.syntheticAudience.name && this.syntheticAudience.composition &&
                this.selectedAudienceCount === 0) {
                return 'Audience selection is required.';
            } else if (this.syntheticAudience.name && !this.syntheticAudience.composition &&
                this.selectedAudienceCount > 0) {
                return 'Composition is required.';
            } else if (!this.syntheticAudience.name && this.syntheticAudience.composition &&
                this.selectedAudienceCount > 0) {
                return 'Synthetic Audience Name is required.';
            } else if (this.syntheticAudience.name && !this.syntheticAudience.composition &&
                this.selectedAudienceCount === 0) {
                return 'Composition and Audience selection are required.';
            } else if (!this.syntheticAudience.name && this.syntheticAudience.composition &&
                this.selectedAudienceCount === 0) {
                return 'Synthetic Audience Name and Audience selection are required.';
            } else if (!this.syntheticAudience.name && !this.syntheticAudience.composition &&
                this.selectedAudienceCount > 0) {
                return 'Synthetic Audience Name and Composition are required.';
            }

            return 'Synthetic Audience Name, Composition and Audience are required.';
        }
    },
    watch: {
        filterAudiencesTerm: {
            immediate: true,
            handler () {
                this.debounceFilterAudiences();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-card-foot {
  span.is-pulled-left {
    margin-right: auto;
  }
}

.is-full-width{
  width:100%;
  margin:auto;
}

.fixed-notification-in-dialog {
    .notification {
        border-radius: 0;
    }
}
</style>
