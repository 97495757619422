<template>
    <div>
        <b-collapse
            aria-id="contentIdForA11y2"
            class=""
            animation="slide"
            v-model="isOpen"
        >
            <template #trigger>
                <div
                    class="header"
                    role="button"
                    aria-controls="contentIdForA11y2"
                >
                    <p class="is-size-4 is-pulled-left title-strong">
                      <v-icon v-if="isOpen" class="icon" name="chevron-down"/>
                      <v-icon v-else class="icon" name="chevron-right"/>
                      Survey XML
                    </p>
                    <p class="is-size-5 is-pulled-right title-strong">
                        <span>
                            <v-icon class="icon" :name="headingIcon"/> {{headingText}}
                        </span>
                    </p>
                </div>
            </template>

            <div v-if="isLoading">
                <v-icon name="spinner" spin/>
            </div>
            <div v-else>
              <div class="columns" style="width:100%">
                  <div class="column is-two-thirds">
                      <b-message :type="messageType" has-icon> <span v-html="messageText"></span> </b-message>
                  </div>

                  <div class="column">
                    <div v-if="isSurveyXmlProcessing">
                        <v-icon name="spinner" spin/> Processing...
                    </div>
                    <div v-else-if="surveyId" class="button-container is-pulled-right">
                        <button class="button is-primary is-outlined"
                            v-if="surveyXmlFileState"
                            @click="showConfirmDeleteModal"
                            :disabled="isSurveyXmlProcessing">
                            {{ isSurveyXmlInError ? 'Reset' : 'Remove' }}
                        </button>
                        <button class="button is-primary is-outlined"
                            v-if="surveyXmlFileState && !isSurveyXmlInError"
                            @click="downloadSurveyXmlFile"
                            :disabled="isSurveyXmlProcessing">
                            Download
                        </button>
                        <button class="button is-primary is-outlined"
                            v-if="!surveyXmlFileState && isAllPredecessorComplete"
                            @click="calculateSurveyMetrics"
                            :disabled="isSurveyXmlProcessing">
                            Generate XML
                        </button>
                    </div>
                  </div>
              </div>

              <div style="width:80%; margin:auto;" v-if="errorMessage && !isSurveyXmlProcessing">
                  <b-message title="Invalid Survey Definition" type="is-danger" has-icon :closable="false">
                      <p>A problem was encountered when generating XML file. Please contact WW App Support.</p>
                      {{ errorMessage }}
                  </b-message>
              </div>
            </div>

        </b-collapse>
    </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { EventBus } from '@/event-bus';

import surveyMetricService from '@/services/survey-metric-service.js';
import surveyXmlFileService from '@/services/survey-xml-file-service.js';

export default {
    name: 'SurveyMetrics',
    components: {},
    props: {
        surveyId: {
            type: Number,
            required: false
        }
    },
    data () {
        return {
            errorMessage: '',
            isLoading: true,
            isCalculating: false,
            isDownloading: false,
            surveyXmlFileState: null,
            isSurveyXmlFileOutdated: false,
            timerId: 0,
            xmlStateMessages: '',
            surveyXmlFileName: '',

            isOpen: false,
            headingIcon: '',
            headingText: '',
            messageType: '',
            messageText: ''
        };
    },
    async created () {
        this.setDefaultState();
        EventBus.$on('surveyRespondentNext', () => {
            this.isOpen = true;
        });

        const predecessorHasChanged = () => {
            if (this.surveyId && this.surveyXmlFileState && !this.isSurveyXmlProcessing && !this.isSurveyXmlInError) {
                this.isSurveyXmlFileOutdated = true;
                this.setInternalState();
            }
        };

        EventBus.$on('surveyDetailSaved', predecessorHasChanged);
        EventBus.$on('surveyDefinitionChanged', predecessorHasChanged);

        if (this.surveyId) {
            await this.startPolling();
        }

        this.isLoading = false;
    },
    methods: {
        async startPolling () {
            await this.checkSurveyXMLFileState();
            if (this.isSurveyXmlProcessing) {
                // if it's in processing state poll every 10 seconds.
                this.timerId = setInterval(await this.pollXmlStatus, 10000);
            }
        },
        async pollXmlStatus () {
            await this.checkSurveyXMLFileState();

            // halt polling if the state is anything other than processing
            if (!this.isSurveyXmlProcessing) {
                clearInterval(this.timerId);
            }
        },
        async checkSurveyXMLFileState () {
            if (this.surveyId) {
                try {
                    const response = await surveyXmlFileService.getSurveyXmlFileStateForSurvey(this.surveyId);
                    const surveyFileState = response.data;
                    this.isSurveyXmlFileOutdated = surveyFileState.isOutOfDate;
                    this.surveyXmlFileState = surveyFileState.state;
                    this.xmlStateMessages = surveyFileState.messages;
                    this.surveyXmlFileName = generateSurveyFileName(surveyFileState);
                    this.setInternalState();
                    // console.log(surveyFileState);
                } catch (error) {
                    console.log('This survey does not contain any xml file.');
                    this.setInternalState();
                }
            }
        },
        async calculateSurveyMetrics () {
            if (this.surveyId) {
                try {
                    this.isCalculating = true;
                    this.surveyXmlFileState = 'Processing';
                    const response = await surveyMetricService.calculateSurveyMetrics(
                        this.surveyId
                    );

                    console.log('Response: ' + response.status);
                    // Start polling for the xml generation status
                    await this.startPolling();
                } catch (error) {
                    this.surveyXmlFileState = 'Error';
                    this.errorMessage = error.message;
                } finally {
                    this.isCalculating = false;
                }
            }
        },
        showConfirmDeleteModal () {
            this.$buefy.dialog.confirm({
                title: 'Delete Survey Xml File',
                message: 'This will permanently delete the survey xml file.<br>Do you wish to continue?',
                type: 'is-danger',
                confirmText: 'Delete',
                cancelText: 'Cancel',
                hasIcon: true,
                onConfirm: () => this.deleteSurveyXmlFile()
            });
        },
        async deleteSurveyXmlFile () {
            if (this.surveyId) {
                try {
                    await surveyXmlFileService.deleteSurveyXmlFile(this.surveyId);
                    this.surveyXmlFileState = null;
                    this.setInternalState();
                } catch (error) {
                    this.errorMessage = error.message;
                }
            }
        },
        async downloadSurveyXmlFile () {
            if (this.surveyId) {
                try {
                    this.isDownloading = true;
                    const response = await surveyXmlFileService.downloadSurveyXmlFile(this.surveyId);
                    const suggestedFileName = response.headers['x-suggested-filename'];
                    const fileName = suggestedFileName || 'survey.xml';
                    saveAs(response.data, fileName);
                } catch (error) {
                    this.errorMessage = error.message;
                } finally {
                    this.isDownloading = false;
                }
            }
        },
        setDefaultState () {
            this.messageType = 'is-info';
            this.messageText = 'Survey Details, Definition and Respondent files are needed before a Survey XML file can be generated.';
            this.headingIcon = 'exclamation-circle';
            this.headingText = 'Survey Detail, Definition and Respondent Data required';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setNoFileState () {
            this.messageType = 'is-info';
            this.messageText = 'Ready to generate Survey XML file.';
            this.headingIcon = 'exclamation-circle';
            this.headingText = 'Pending';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setProcessingState () {
            this.messageType = 'is-info';
            this.messageText = 'Generating Survey XML file.';
            this.headingIcon = 'exclamation-circle';
            this.headingText = 'Processing';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setInvalidFileState () {
            this.messageType = 'is-danger';
            this.messageText = 'Error while generating Survey XML file. Please ensure that a valid Survey Definition file and a valid Survey Respondent File have been uploaded.';
            this.headingText = 'Attention';
            this.headingIcon = 'exclamation-triangle';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setValidFileState () {
            this.messageType = 'is-success';
            this.messageText = 'Survey XML file available for download.';
            this.headingText = 'Survey XML Available';
            this.headingIcon = 'check-circle';
            // Set the global state of this component to complete.
            this.updateStateInVueXStore(true);
        },
        setOutOfDateFileState () {
            this.messageType = 'is-warning';
            this.messageText = 'Survey Detail, Definition or Data has changed. Your Survey XML is out of date, and should be removed and regenerated.';
            this.headingText = 'Survey XML Out of Date';
            this.headingIcon = 'exclamation-triangle';
            // Set the global state of this component to complete as the xml file exists, even though its out of dated.
            this.updateStateInVueXStore(true);
        },
        updateStateInVueXStore (isComplete) {
            // Set the global state of this component.
            this.$store.commit('setIsSurveyXmlComplete', isComplete);
        },
        setInternalState () {
            var setOtherStateFunc = () => {
                if (this.isSurveyXmlProcessing) {
                    this.setProcessingState();
                } else if (this.isSurveyXmlInError) {
                    this.setInvalidFileState();
                } else {
                    if (this.isSurveyXmlFileOutdated) {
                        this.setOutOfDateFileState();
                    } else {
                        this.setValidFileState();
                    }
                    this.isOpen = true; // We're ready to download, so ensure the download is open
                }
            };

            if (this.isAllPredecessorComplete) {
                if (this.surveyXmlFileState) {
                    setOtherStateFunc();
                } else {
                    this.setNoFileState();
                }
            } else {
                if (this.surveyXmlFileState) {
                    setOtherStateFunc();
                } else {
                    this.setDefaultState();
                }
            }
        }
    },
    computed: {
        isSurveyXmlProcessing () {
            return this.surveyXmlFileState === 'Processing';
        },
        isSurveyXmlInError () {
            return this.surveyXmlFileState === 'Error';
        },
        isAllPredecessorComplete () {
            return this.$store.state.isSurveyDetailComplete &&
                this.$store.state.isSurveyDefinitionComplete &&
                this.$store.state.isSurveyFileComplete;
        }
    },
    watch: {
        isAllPredecessorComplete (value) {
            if (!value) {
                this.isSurveyXmlFileOutdated = true;
            }
            this.setInternalState();
        }
    }
};

function generateSurveyFileName (surveyXmlFile) {
    if (!surveyXmlFile) {
        return 'Survey not Valid';
    }

    // return name in the pattern "Survey_N_YYYY-MM-DD.xml" where the date is the last modified date of the file
    var slicedDate = surveyXmlFile.lastModified.slice(0, 10); // take the YYYY-MM-DD part of the date
    return 'Survey_' + surveyXmlFile.surveyId + '_' + slicedDate + '.xml';
}
</script>

<style scoped lang="scss">
.button-container {
    margin-top: 10px;
    button {
        margin-left: 5px;
        margin-right: 5px;
    }
}

div.header {
  height: 3em;
  padding-top: 0.5em;
}
.title-strong {
  font-weight: 600;
}
.button-container {
  margin-top:10px;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.message {
  // override inherited text alignment for the b-message box
  text-align: left;
}
</style>
