<template>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div>
        <b-collapse
                aria-id="contentIdForA11y2"
                class=""
                animation="slide"
                v-model="isOpen"
            >
                <template #trigger>
                    <div
                        class="header"
                        role="button"
                        aria-controls="contentIdForA11y2"
                    >
                        <p class="is-size-4 is-pulled-left title-strong">
                        <v-icon v-if="isOpen" class="icon" name="chevron-down"/>
                        <v-icon v-else class="icon" name="chevron-right"/>
                        Survey Detail
                        </p>
                        <p class="is-size-5 is-pulled-right title-strong">
                            <!-- Have to cover all combinations of the two boolean flags here for the different messages -->
                            <span v-if="!isComplete && !hasChanges()">
                                <v-icon class="icon" name="exclamation-circle"/> Incomplete
                            </span>

                            <span v-if="!isComplete && hasChanges()">
                                <v-icon class="icon" name="exclamation-circle"/> Incomplete with unsaved changes
                            </span>

                            <span v-if="isComplete && hasChanges()">
                                <v-icon class="icon" name="exclamation-circle"/> Unsaved Changes
                            </span>

                            <span v-if="isComplete && !hasChanges()">
                            <v-icon class="icon" name="check-circle"/> Complete
                            </span>
                        </p>
                    </div>
                </template>

            <b-notification type="is-danger" v-if="errorMessage" :closable="false">
                <i class="fas fa-exclamation-circle"></i> {{ errorMessage }}
            </b-notification>
            <b-notification type="is-danger" v-if="validationErrorMessages && validationErrorMessages.length > 0" :closable="false">
                <div v-for="errorMsg in validationErrorMessages" :key="errorMsg">
                    <i class="fas fa-exclamation-circle"></i> {{ errorMsg }}
                </div>
            </b-notification>
            <div v-if="isLoading">
            <v-icon name="spinner" spin/>
            </div>
            <div v-if="!isLoading" class="columns is-desktop" style="width:100%">
                <div class="column">
                        <!-- input fields -->
                     <ValidationProvider :rules="{required: true, regex:/^[\w\-\s]+$/}" name="Name" v-slot="{ errors, valid }">
                        <b-field label="Name" horizontal
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">
                            <b-input
                                type="text"
                                v-model="model.name"
                                required
                                :disabled="isSaving||isLoading"
                            />
                        </b-field>
                    </ValidationProvider>
                    <!-- <b-field label="State" horizontal>
                        <p class="is-pulled-left">{{model.id ? model.state : 'Unsaved'}}</p>
                    </b-field> -->
                    <b-field label="Date" horizontal>
                        <b-datepicker
                            v-model="modelSurveyDate"
                            placeholder="Select Date of Survey..."
                            trap-focus
                            required
                            icon="fa fa-calendar"
                            :date-formatter="dateHelperService.formatForClientLocale"
                            :disabled="isSaving||isLoading">
                        </b-datepicker>
                    </b-field>
                    <ValidationProvider :rules="{required: true, regex:/^[\w\-\s]+$/}" name="Client" v-slot="{ errors, valid }">
                        <b-field label="Client" horizontal
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">
                            <b-input
                                type="text"
                                v-model="model.clientName"
                                required
                                :disabled="isSaving||isLoading"
                            />
                        </b-field>
                    </ValidationProvider>
                    <ValidationProvider :rules="{required: true, regex:/^[\w\-\s]+$/}" name="Category" v-slot="{ errors, valid }">
                        <b-field label="Category" horizontal
                        :type="{ 'is-danger': errors[0], 'is-success': valid }"
                        :message="errors">
                            <b-input
                                type="text"
                                v-model="model.categoryName"
                                required
                                :disabled="isSaving||isLoading"
                            />
                        </b-field>
                    </ValidationProvider>
                    <b-field label="Market" horizontal>
                        <b-input
                            type="text"
                            v-model="model.marketName"
                            required
                            :disabled="isSaving||isLoading"
                        />
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Description" horizontal>
                        <b-input
                            type="textarea"
                            rows="2"
                            placeholder="Optional Survey Description"
                            maxlength=1000
                            v-model="model.description"
                            :disabled="isSaving||isLoading"
                        />
                    </b-field>

                    <b-field label="Field Methodology" horizontal>
                        <b-input
                            type="text"
                            v-model="model.fieldMethodology"
                            required
                            :disabled="isSaving||isLoading"
                        />
                    </b-field>
                    <b-field label="Total Audience Description" horizontal>
                        <b-input
                            type="text"
                            v-model="model.totalAudienceDescription"
                            required
                            :disabled="isSaving||isLoading"
                        />
                    </b-field>
                    <b-field label="Is Client Confidential" horizontal>
                        <b-checkbox class="is-pulled-left"
                            v-model="model.isClientConfidential"
                            required
                            :disabled="isSaving||isLoading"
                        />
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-two-thirds">
                <b-message type="is-info" has-icon>
                All fields are required before saving the survey details.
                <br/>
                Please save before proceeding.
                </b-message>
                </div>
                <!-- save action -->
                <div class="column">
                <div class="button-container is-pulled-right">
                    <button
                    class="button is-outlined is-success"
                    :title="generatedSaveAriaText"
                    @click="handleSubmit(save)" :disabled="isSaving||isLoading||!isComplete">
                    Save
                    </button>
                    <button class="button is-success" @click="handleSubmit(next)" :disabled="!isComplete || hasChanges()">Next</button>
                </div>
                </div>
            </div>
        </b-collapse>
        </div>
    </ValidationObserver>
</template>

<script>
import constants from '@/constants.js';
import _ from 'lodash';

import { EventBus } from '@/event-bus';
import surveyService from '@/services/survey-service.js';
import { dateHelperService } from '@/services/date-helper-service.js';
import { required, regex } from 'vee-validate/dist/rules';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';

extend('required', {
    ...required,
    message: 'This Field is required'
});

extend('regex', {
    ...regex,
    message: 'This field cannot contain ASCII characters'
});

export default {
    name: 'SurveyDetail',
    components: {
        ValidationObserver,
        ValidationProvider
    },
    props: {
        surveyId: {
            type: Number,
            required: false
        }
    },
    data () {
        return {
            surveyService,
            dateHelperService,
            errorMessage: '',
            validationErrorMessages: [],
            isLoading: true,
            isSaving: false,
            model: {
                name: '',
                description: '',
                clientName: '',
                categoryName: '',
                marketName: '',
                fieldMethodology: '',
                totalAudienceDescription: '',
                isClientConfidential: false,
                surveyDate: dateHelperService.formatForAPI(new Date()), // format as if it had just arrived from the api
                state: constants.SURVEY_STATE.IN_PROGRESS
            },
            isOpen: true,
            originalContent: undefined
        };
    },
    async created () {
        if (this.surveyId) {
            try {
                const response = await surveyService.getSurvey(this.surveyId);
                this.model = response.data;
            } catch (error) {
                this.errorMessage = error.message;
            }
        }
        this.setOriginalContent();
        // Set the global state of this component. e.g. complete or incomplete.
        this.updateStateInVueXStore();
        this.isLoading = false;
    },
    methods: {
        async save () {
            // Validate the survey details
            this.validationErrorMessages = this.validateSurveyDetails(this.model);
            if (this.validationErrorMessages.length === 0) {
                this.isSaving = true;

                try {
                    if (this.model && this.model.id) {
                        const response = await surveyService.updateSurvey(this.model);
                        this.model = response.data;
                        this.setOriginalContent();
                        // Set the global state of this component. e.g. complete or incomplete.
                        this.updateStateInVueXStore();
                    } else if (this.model) {
                        const response = await surveyService.addSurvey(this.model);
                        this.model = response.data;
                        // Check whether we are in create mode, and redirect to edit mode
                        if (this.$route.path === '/survey') {
                            this.$router.push('/survey/' + this.model.id);
                        }
                    }
                    EventBus.$emit('surveyDetailSaved');
                } catch (error) {
                    const errorResponse = (await error).response;
                    if (
                        errorResponse &&
                        errorResponse.data &&
                        errorResponse.data.message
                    ) {
                        this.errorMessage = errorResponse.data.message;
                    } else {
                        this.errorMessage = error.message;
                    }
                } finally {
                    this.isSaving = false;
                }
            }
        },
        validateSurveyDetails () {
            // Check if all the required fields are populated.
            var validationErrorMsgs = [];
            if (!this.model.name) {
                validationErrorMsgs.push('Survey name is required.');
            }
            if (!this.model.clientName) {
                validationErrorMsgs.push('Client name is required.');
            }
            if (!this.model.categoryName) {
                validationErrorMsgs.push('Category name is required.');
            }
            if (!this.model.marketName) {
                validationErrorMsgs.push('Market name is required.');
            }
            if (!this.model.totalAudienceDescription) {
                validationErrorMsgs.push('Total Audience Description is required.');
            }
            return validationErrorMsgs;
        },
        next () {
            EventBus.$emit('surveyDetailNext');
            this.isOpen = false;
        },
        serializedContent () {
            const content = _.cloneDeep(this.model);

            // Pick out the properties we care about from the model
            content.projectJSON = JSON.stringify(content.settings);
            return _.pick(content, ['name', 'description', 'clientName', 'categoryName', 'marketName', 'fieldMethodology', 'totalAudienceDescription', 'isClientConfidential', 'surveyDate']);
        },
        hasChanges () {
            const currentContent = this.serializedContent();
            return !_.isEqual(this.originalContent, currentContent);
        },
        setOriginalContent () {
            this.originalContent = this.serializedContent();
        },
        updateStateInVueXStore () {
            if (this.isComplete && !this.hasChanges()) {
                // Set the global state of this component to complete.
                this.$store.commit('setIsSurveyDetailComplete', true);
            } else {
                // Set the global state of this component to incomplete.
                this.$store.commit('setIsSurveyDetailComplete', false);
            }
        }
    },
    computed: {
        modelSurveyDate: {
            // use a computed property with get/set here as the datepicker expects a Date, not a string (from JSON)
            get: function () {
                return new Date(this.model.surveyDate);
            },
            set: function (updatedDate) {
                // this will set the underlying property to the ISO standard format (with tz as UTC)
                this.model.surveyDate = dateHelperService.formatForAPI(updatedDate);
            }
        },
        isComplete () {
            return this.model.name && this.model.surveyDate && this.model.clientName &&
                this.model.categoryName && this.model.marketName &&
                this.model.fieldMethodology && this.model.totalAudienceDescription;
        },
        generatedSaveAriaText () {
            var validationErrors = this.validateSurveyDetails(this.model);
            if (validationErrors.length === 0) {
                return 'Save Survey Details';
            } else {
                return validationErrors.join(' ');
            }
        }
    }
};
</script>

<style scoped lang="scss">
div.header {
    height: 3em;
    padding-top: 0.5em;
}

.title-strong {
    font-weight: 600;
}

.button-container {
    margin-top: 10px;

    button {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.message {
    // override inherited text alignment for the b-message box
    text-align: left;
}
</style>
