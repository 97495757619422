<template>
    <div>
        <b-collapse
            aria-id="contentIdForA11y2"
            class=""
            animation="slide"
            v-model="isOpen"
        >
            <template #trigger>
                <div
                    class="header"
                    role="button"
                    aria-controls="contentIdForA11y2"
                >
                    <p class="is-size-4 is-pulled-left title-strong">
                      <v-icon v-if="isOpen" class="icon" name="chevron-down"/>
                      <v-icon v-else class="icon" name="chevron-right"/>
                      Survey Respondent Data
                    </p>
                    <p class="is-size-5 is-pulled-right title-strong">
                        <span>
                            <v-icon class="icon" :name="headingIcon"/> {{headingText}}
                        </span>
                    </p>
                </div>
            </template>

            <div v-if="isLoading">
                <v-icon name="spinner" spin/>
            </div>
            <div v-else>
              <div class="columns" style="width:100%">
                  <div class="column is-two-thirds">
                      <b-message :type="messageType" has-icon> <span v-html="messageText"></span> </b-message>
                  </div>

                  <div class="column">
                    <div v-if="isProcessing">
                        <v-icon name="spinner" spin/> Processing...
                    </div>
                    <div v-else-if="surveyId" class="button-container is-pulled-right">
                        <button class="button is-primary is-outlined" v-if="!surveyFile" @click="showFileSelector">Add Survey Respondent File</button>
                        <button class="button is-primary is-outlined" v-if="surveyFile && $can('download','SurveyFiles')" @click="downloadSurveyFile">Download</button>
                        <button class="button is-primary is-outlined" v-if="surveyFile" @click="showConfirmDeleteModal">Remove Survey Respondent File</button>
                    </div>
                  </div>
              </div>

              <div style="width:80%; margin:auto;" v-if="isInvalidSurveyFile && !isProcessing">
              <!-- <div style="width:80%; margin:auto;" v-if="!isProcessing"> -->
                  <b-message title="Invalid Survey Definition" type="is-danger" has-icon :closable="false" v-if="surveyFileId && errorMessage">
                      <p>Raw data file (<b>{{surveyFileName}}</b>) processing failed with the following error:</p>
                      {{ errorMessage }}
                  </b-message>
                  <b-message class="has-text-left" title="Validation Errors" type="is-danger" has-icon :closable="false"
                      v-if="surveyFileId && validationErrorMessages && validationErrorMessages.length > 0">
                      <p>Raw data (<b>{{surveyFileName}}</b>) processing failed with the following validation errors:</p>
                      <ul style="list-style:inside">
                        <li v-for="message in validationErrorMessages" :key="message">
                          {{ message }}
                        </li>
                      </ul>
                  </b-message>
              </div>
            </div>
            <div class="columns">
                <div class="column is-two-thirds"> </div>
                <!-- save action -->
                <div class="column">
                  <div class="button-container is-pulled-right">
                    <button class="button is-success" @click="next" :disabled="!isValidSurveyFile">Next</button>
                  </div>
                </div>
            </div>

        </b-collapse>
    </div>
</template>

<script>
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { EventBus } from '@/event-bus';

import FileSelector from '@/components/FileSelector.vue';
import surveyFileService from '@/services/survey-file-service.js';

export default {
    name: 'SurveyFile',
    props: {
        surveyId: {
            type: Number,
            required: false
        }
    },
    data () {
        return {
            errorMessage: '',
            isLoading: true,
            isSaving: false,
            isDownloading: false,
            surveyFile: null,

            isOpen: false,
            isProcessing: false,
            processedSurveyFile: null,
            validationErrorMessages: [],
            headingIcon: 'exclamation-circle',
            headingText: 'Survey Detail and Definition required',
            messageType: 'is-info',
            messageText: 'Please complete the Survey Detail section before uploading a respondent data file.'
        };
    },
    async created () {
        EventBus.$on('surveyDefinitionNext', () => {
            this.isOpen = true;
        });

        // Set the global state of this component to incomplete as default.
        this.updateStateInVueXStore(false);

        EventBus.$on('surveyDefinitionChanged', async () => {
            // if a respondent data file exists, then we need to reprocess it to check it against the new definition file.
            if (this.surveyFile) {
                this.isOpen = true;
                await this.processSurveyFile(this.surveyFile.id);
            }
        });

        if (this.surveyId) {
            this.setNoFileState();
            this.isOpen = true; // un-collapse/open this section if we've got a surveyid set - either we're working on this section, or it's valid
            try {
                const response = await surveyFileService.getSurveyFileForSurvey(this.surveyId);
                this.surveyFile = response.data;
                await this.processSurveyFile(this.surveyFile.id);
            } catch (error) {
                console.log('This survey does not contain any raw survey results file.');
            }
        }

        this.isLoading = false;
    },
    methods: {
        async save (surveyFile) {
            this.isSaving = true;
            try {
                // create surveyFile
                const formData = new FormData();
                formData.append('File', surveyFile.file);
                formData.append('SurveyId', this.surveyId);
                formData.append('Name', surveyFile.name);
                const response = await surveyFileService.addSurveyFile(formData);
                this.surveyFile = response.data;
                await this.processSurveyFile(this.surveyFile.id);
            } catch (error) {
                const errorResponse = (await error).response;
                if (
                    errorResponse &&
                    errorResponse.data &&
                    errorResponse.data.message
                ) {
                    this.errorMessage = errorResponse.data.message;
                } else {
                    this.errorMessage = error.message;
                }
            } finally {
                this.isSaving = false;
                EventBus.$emit('surveyDataChanged');
            }
        },
        showFileSelector () {
            this.$buefy.modal.open({
                parent: this,
                component: FileSelector,
                hasModalCard: true,
                props: {
                    headerText: 'Select a Survey Respondent file'
                },
                events: {
                    addFile: this.save
                }
            });
        },
        showConfirmDeleteModal () {
            this.$buefy.dialog.confirm({
                title: 'Delete Survey File',
                message: this.$sanitize(`This will permanently delete the raw respondent file <b>${this.surveyFile.name}</b>.<br>Do you wish to continue?`),
                type: 'is-danger',
                confirmText: 'Delete',
                cancelText: 'Cancel',
                hasIcon: true,
                onConfirm: () => this.deleteSurveyFile()
            });
        },
        async deleteSurveyFile () {
            if (this.surveyFile && this.surveyFile.id) {
                try {
                    await surveyFileService.deleteSurveyFile(this.surveyFile.id);
                    this.surveyFile = null;
                    this.setNoFileState();
                    EventBus.$emit('process-survey-file', null);
                } catch (error) {
                    this.errorMessage = error.message;
                } finally {
                    EventBus.$emit('surveyDataChanged');
                }
            }
        },
        async downloadSurveyFile () {
            if (this.surveyFile && this.surveyFile.id) {
                try {
                    this.isDownloading = true;
                    const response = await surveyFileService.downloadSurveyFile(this.surveyFile.id);
                    saveAs(response.data, this.surveyFile.name);
                } catch (error) {
                    this.errorMessage = error.message;
                } finally {
                    this.isDownloading = false;
                }
            }
        },
        async processSurveyFile (id) {
            if (id) {
                this.isProcessing = true;
                this.setProcessingState();
                try {
                    // process the survey file
                    const response = await surveyFileService.processSurveyFile(id);
                    this.processedSurveyFile = response.data;
                    if (this.processedSurveyFile && !_.isEmpty(this.processedSurveyFile.validationErrorMessages)) {
                        this.errorMessage = '';
                        this.validationErrorMessages = this.processedSurveyFile.validationErrorMessages;
                        console.log('foo');
                        this.setInvalidFileState();
                    } else {
                        this.errorMessage = '';
                        this.validationErrorMessages = [];
                        // console.log('baz ' + this.isValidSurveyFile + ' (' + this.errorMessage + ') & (' + this.validationErrorMessages.length + ')');
                        console.log('isvalid: ' + this.processedSurveyFile + '&&' +
                          this.processedSurveyFile.processedParticipants + '&&' +
                          _.isEmpty(this.processedSurveyFile.validationErrorMessages) + '&&' +
                          _.isEmpty(this.validationErrorMessages) + '&&' +
                          _.isEmpty(this.errorMessage));
                        this.setValidFileState(this.processedSurveyFile.hasWeightFactors);
                    }
                } catch (error) {
                    const errorResponse = (await error).response;
                    if (
                        errorResponse &&
                        errorResponse.data &&
                        errorResponse.data.message
                    ) {
                        this.errorMessage = errorResponse.data.message;
                    } else {
                        this.errorMessage = error.message;
                    }
                    console.log('bar');
                    this.setInvalidFileState();
                } finally {
                    this.isProcessing = false;
                }
            } else {
                this.processedSurveyFile = null;
            }
        },
        setNoFileState () {
            this.messageType = 'is-info';
            this.messageText = 'Please upload a survey respondent file.';
            this.headingIcon = 'exclamation-circle';
            this.headingText = 'Incomplete';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setProcessingState () {
            this.messageType = 'is-info';
            this.messageText = 'Processing uploaded survey respondent file.';
            this.headingIcon = 'exclamation-circle';
            this.headingText = 'Processing';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setInvalidFileState () {
            this.messageType = 'is-danger';
            this.messageText = this.$sanitize(`Error while processing survey respondent file  <b>${this.surveyFile.name}</b>.`);
            this.headingText = 'Invalid Survey Respondent Data';
            this.headingIcon = 'exclamation-triangle';
            // Set the global state of this component to incomplete.
            this.updateStateInVueXStore(false);
        },
        setValidFileState (hasWeightFactors) {
            this.messageType = 'is-success';
            this.messageText = this.$sanitize(`Successfully processed survey respondent file  <b>${this.surveyFile.name}</b>.`);

            if (hasWeightFactors) {
                this.messageText += '<br>The weight factors have been correctly applied for all participants.';
            }

            this.headingText = 'Valid Survey Respondent Data';
            this.headingIcon = 'check-circle';
            // Set the global state of this component to complete.
            this.updateStateInVueXStore(true);
        },
        next () {
            EventBus.$emit('surveyRespondentNext');
            this.isOpen = false;
        },
        updateStateInVueXStore (isComplete) {
            // Set the global state of this component.
            this.$store.commit('setIsSurveyFileComplete', isComplete);
        }
    },
    computed: {
        surveyFileId () {
            return this.surveyFile?.id;
        },
        surveyFileName () {
            return this.surveyFile?.name;
        },
        isValidSurveyFile () {
            if (this.processedSurveyFile &&
                // this.processedSurveyFile.processedParticipants && // this isn't used anywher else
                _.isEmpty(this.processedSurveyFile.validationErrorMessages) &&
                _.isEmpty(this.validationErrorMessages) &&
                _.isEmpty(this.errorMessage)) {
                return true;
            }
            return false;
        },
        isInvalidSurveyFile () {
            if (!_.isEmpty(this.errorMessage) || !_.isEmpty(this.validationErrorMessages)) {
                return true;
            }
            return false;
        }
    }
};
</script>

<style scoped lang="scss">
.button-container {
  margin-top:10px;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

div.header {
  height: 3em;
  padding-top: 0.5em;
}
.title-strong {
  font-weight: 600;
}
.button-container {
  margin-top:10px;
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
}
.message {
  // override inherited text alignment for the b-message box
  text-align: left;
}
</style>
