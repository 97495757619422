<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">{{ headerText }}</p>
        </header>
        <section class="modal-card-body">
            <b-message type="is-info" has-icon>
                Please upload an <b>XLS</b> or <b>XLSX</b> file containing your survey respondent data.
            </b-message>

            <b-notification type="is-danger" v-if="errorMessage" :closable="false">
                {{ errorMessage }}
            </b-notification>

            <b-field>
                <b-upload v-model="model.file" drag-drop expanded>
                    <section class="section">
                    <div class="content has-text-centered">
                        <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your files here or click to upload</p>
                    </div>
                    </section>
                </b-upload>
            </b-field>

            <b-field label="File Name" horizontal>
                <b-input
                    type="text"
                    :disabled="!model.file"
                    placeholder="Survey Respondent Filename"
                    v-model="model.name"
                    required>
                </b-input>
            </b-field>

        </section>
        <footer class="modal-card-foot" style="width:100%">
            <span class="is-pulled-left is-italic"> {{requiredText}} </span>
            <button  type="button" class="button" @click="onCancelAction">Cancel</button>
            <button type="button" class="button is-primary" @click="addFile" :disabled="!isValidModel">Add</button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'FileSelector',
    props: {
        headerText: {
            type: String,
            required: false
        }
    },
    data () {
        return {
            errorMessage: '',
            model: {
                name: undefined,
                file: null
            }
        };
    },
    created () {
        this.headerText = this.headerText ?? 'Select a file';
    },
    methods: {
        addFile () {
            this.$emit('addFile', this.model);
            this.$parent.close();
        },
        onCancelAction () {
            this.$parent.close();
        },
        deleteFile () {
            this.model.file = null;
        },
        isFileXLSX () {
            if (this.model.file) {
                var fileName = this.model.file.name.toLowerCase();
                var regex = new RegExp('(.*?)\\.(xls[x]?)$');
                return regex.test(fileName);
            }
            return false;
        }
    },
    computed: {
        isValidModel () {
            return this.model.name && this.model.file && this.isFileXLSX();
        },
        requiredText () {
            if (this.model.file && this.model.name) {
                return '';
            } else if (this.model.file && !this.model.name) {
                return 'File Name is required.';
            }

            return 'Survey Respondent File is required.';
        }
    },
    watch: {
        'model.file': function () {
            if (!this.isFileXLSX()) {
                this.errorMessage = "The file must be an 'XLSX' or 'XLS' file.";
            } else {
                this.model.name = this.model.name
                    ? this.model.name + ' - ' + this.model.file.name
                    : this.model.file.name;
                this.errorMessage = '';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-card-foot {
  span.is-pulled-left {
    margin-right: auto;
  }
}
</style>
