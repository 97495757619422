<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">Add Survey Definition File</p>
        </header>
        <section class="modal-card-body">
            <b-message type="is-info" has-icon>
              Please upload your survey definition (datamap or codebook) file and select the provider used for the survey.
            </b-message>

            <b-notification type="is-danger" v-if="errorMessage" :closable="false">
                {{ errorMessage }}
            </b-notification>

            <div class="columns">
              <div class="column is-two-thirds">
                <b-field>
                    <b-upload v-model="model.file" drag-drop>
                    <!-- <b-upload v-model="model.file" drag-drop expanded> -->
                        <section class="section">
                        <div class="content has-text-centered">
                            <p>
                            <b-icon icon="upload" size="is-large"></b-icon>
                            </p>
                            <p>Drop your files here or click to upload</p>
                        </div>
                        </section>
                    </b-upload>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Survey Provider">
                    <b-radio v-model="model.providerName"
                        name="name"
                        native-value="Dynata">
                        Dynata
                    </b-radio>
                </b-field>
                <b-field>
                    <b-radio v-model="model.providerName"
                        name="name"
                        native-value="Questback">
                        Questback
                    </b-radio>
                </b-field>
                <b-field>
                    <b-radio v-model="model.providerName"
                        name="name"
                        native-value="GWI">
                        GWI
                    </b-radio>
                </b-field>
              </div>
            </div>

            <b-field label="File Name" horizontal>
                <b-input
                    type="text"
                    :disabled="!model.file"
                    placeholder="Survey Definition Filename"
                    v-model="model.name"
                    required>
                </b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot" style="width:100%">
            <span class="is-pulled-left is-italic"> {{requiredText}} </span>
            <button type="button" class="button" @click="onCancelAction">Cancel</button>
            <button type="button" class="button is-primary" @click="addFile" :disabled="!isValidModel">Add</button>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'DatamapFileSelector',
    data () {
        return {
            errorMessage: '',
            model: {
                name: undefined,
                file: null,
                providerName: undefined
            }
        };
    },
    methods: {
        addFile () {
            this.$emit('addFile', this.model);
            this.$parent.close();
        },
        onCancelAction () {
            this.$parent.close();
        },
        deleteFile () {
            this.model.file = null;
        },
        isFileXLSX () {
            if (this.model.file) {
                var fileName = this.model.file.name.toLowerCase();
                var regex = new RegExp('(.*?)\\.(xls[x]?)$');
                return regex.test(fileName);
            }
            return false;
        }
    },
    computed: {
        isValidModel () {
            return this.model.name && this.model.file && this.model.providerName && this.isFileXLSX();
        },
        requiredText () {
            if (this.model.file && this.model.name && this.model.providerName) {
                return '';
            } else if (this.model.file && this.model.name && !this.model.providerName) {
                return 'Survey Provider selection is required.';
            } else if (this.model.file && !this.model.name && this.model.providerName) {
                return 'File Name is required.';
            } else if ((!this.model.file && this.model.providerName) ||
                       (!this.model.file && this.model.name && this.model.providerName)) {
                return 'Survey Definition File is required.';
            } else if (this.model.file && !this.model.name && !this.model.providerName) {
                return 'Survey Provider and File Name are required.';
            }

            return 'Survey Definition File and Survey Provider are required.';
        }
    },
    watch: {
        'model.file': function () {
            if (!this.isFileXLSX()) {
                this.errorMessage = "The survey definition file must be an 'XLSX' or 'XLS' file.";
            } else {
                this.model.name = this.model.name
                    ? this.model.name + ' - ' + this.model.file.name
                    : this.model.file.name;
                this.errorMessage = '';
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-card-foot {
  span.is-pulled-left {
    margin-right: auto;
  }
}
</style>
