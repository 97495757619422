<template>
  <div>
    <div class="level is-vcentered">
            <div class="level-left">
                <h1 class="title is-size-4">Survey Editor</h1>
            </div>
            <div class="level-right">
                <router-link class="level-item button is-outlined" to="/">Back</router-link>
            </div>
        </div>

    <div class="tile is-ancestor">
      <div class="tile is-parent is-12">
        <div class="tile is-child box has-text-centered">
            <survey-detail :surveyId="surveyId"/>
        </div>
      </div>

      <div class="tile is-parent is-12">
        <div class="tile is-child box has-text-centered">
            <datamap :surveyId="surveyId"/>
        </div>
      </div>

      <div class="tile is-parent is-12">
        <div class="tile is-child box has-text-centered">
            <survey-file :surveyId="surveyId"/>
        </div>
      </div>

      <div class="tile is-parent is-12">
        <div class="tile is-child box has-text-centered">
            <survey-metrics :surveyId="surveyId"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datamap from '../components/Datamap.vue';
import SurveyFile from '../components/SurveyFile.vue';
import SurveyDetail from '../components/SurveyDetail.vue';
import SurveyMetrics from '../components/SurveyMetrics.vue';

export default {
    name: 'CreateSurvey',
    components: {
        SurveyDetail,
        Datamap,
        SurveyFile,
        SurveyMetrics
    },
    props: {
        surveyId: {
            type: Number,
            required: false
        }
    }
};
</script>

<style lang="scss" scoped>
.tile.is-ancestor {
    flex-wrap:wrap;
}
</style>
