import axios from '@/services/axios-auth-wrapper';

export default {
    getDatamapForSurvey: (surveyId) => {
        return axios.get(url + 'survey/' + surveyId);
    },
    addDatamap: (data) => {
        return axios.post(url + 'create', data, {
            headers: {
                'Content-Type': 'multipart/form-data;'
            }
        });
    },
    deleteDatamap: (id) => {
        return axios.delete(url + id);
    },
    downloadDatamap: (id) => {
        return axios.get(url + id + '/download', { responseType: 'blob' });
    },
    processDatamap: (datamapId) => {
        return axios.get(url + datamapId + '/process');
    },
    updateDatamapFields: (data) => {
        return axios.post(url + 'updateFields', data);
    }
};

const url = process.env.VUE_APP_INDIGO_API_URL + '/api/datamap/';
